import * as C from './styles';
import { FaAngleDoubleRight, FaLock, FaUnlock } from 'react-icons/fa';
import { BsGraphUp } from 'react-icons/bs';
import { BiBook } from 'react-icons/bi';
import { IoGitNetwork } from 'react-icons/io5';

import useUtils from '../../hooks/useUtils';
import NavButton from './NavButton';
//import HaiLogo from '../../assets/Logo_HAI_negativo.svg';

export default function NavMenu() {
  const {
    setActiveRoute,
    isNavVisible,
    setIsNavVisible,
    isQuickMode,
    setIsQuickMode,
  } = useUtils();

  return (
    <C.NavSidebar
      $navvisible={isNavVisible}
      onMouseEnter={() => {
        isQuickMode && setIsNavVisible(true);
      }}
      onMouseLeave={() => {
        isQuickMode && setIsNavVisible(false);
      }}
    >
      <C.NavHeader onClick={() => setIsNavVisible(!isNavVisible)}>
        {isNavVisible? <span>ISDB</span>:<C.Symbol $headernavvisible={isNavVisible}>
          <FaAngleDoubleRight />
        </C.Symbol>}
        
  
      </C.NavHeader>

      <C.NavList $navvisible={isNavVisible}>
        <C.NavListTitle>Apps</C.NavListTitle>

        {/* Catalogue Page */}
        <NavButton parentRoute="Catalogue" to="/catalogue/">
          <C.Symbol>
            <BiBook />
          </C.Symbol>
          <C.LinkText $navvisible={isNavVisible}>Catalogue</C.LinkText>
        </NavButton>

        {/* Visualizer Page */}
        <NavButton parentRoute="Visualizer" to="/visualizer/">
          <C.Symbol>
            <BsGraphUp />
          </C.Symbol>
          <C.LinkText $navvisible={isNavVisible}>Visualizer</C.LinkText>
        </NavButton>

        {/* Pipe Routes Page */}
        <NavButton parentRoute="PipeRoutes" to="/pipe-routes/">
          <C.Symbol>
            <IoGitNetwork />
          </C.Symbol>
          <C.LinkText $navvisible={isNavVisible}>Pipe routes</C.LinkText>
        </NavButton>
      </C.NavList>

      <C.NavFooter $navvisible={isNavVisible}>
        <C.FooterLabel>Menu Navigation</C.FooterLabel>
        <C.NavToggle
          $quickmode={isQuickMode}
          onClick={() => setIsQuickMode(!isQuickMode)}
        >
          <C.NavSwitcher $quickmode={isQuickMode}>
            {isQuickMode ? <FaUnlock /> : <FaLock />}
          </C.NavSwitcher>
        </C.NavToggle>
      </C.NavFooter>
    </C.NavSidebar>
  );
}
