import * as C from './styles';
import AvatarPic from '../../assets/avatar.svg';
import useAuth from '../../hooks/useAuth';
import { useEffect, useState } from 'react';
import Loading from '../Loading';
import { BsDoorOpenFill, BsFillBellFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import useProperties from '../../hooks/useProperties';

export default function HeaderMenu() {
  const { user, loadUser, logout } = useAuth();
  const navigate = useNavigate();
  const [userData, setUserData] = useState({
    first_name: 'Loading',
    last_name: 'User',
  });
  const { progressValue, inProgress } = useProperties();

  const [dropdownProfile, setDropdownProfile] = useState(false);

  useEffect(() => {
    loadUser();
  }, [loadUser]);

  useEffect(() => {
    if (user)
      setUserData({ first_name: user.first_name, last_name: user.last_name });
  }, [user]);

  return (
    <C.HeaderTopbar>
      <Loading />
      {/* This section is invisible, only to align with the flex items */}
      <C.TopbarOptions className="invisible">
        <C.NotificationsIcon>
          <BsFillBellFill size={20} />
        </C.NotificationsIcon>

        <C.ProfileContainer
          onClick={() => setDropdownProfile(!dropdownProfile)}
        >
          <C.AvatarPic src={AvatarPic} className="h-16" />
          <C.UsernameLabel>
            {userData.first_name} {userData.last_name}
          </C.UsernameLabel>
        </C.ProfileContainer>
      </C.TopbarOptions>
      {/* End of invisible section */}

      <C.HeaderTitle to="/">Flow TS Demo</C.HeaderTitle>

      <C.TopbarOptions>
        <C.ProgressIcon>
          {inProgress === false ? (
            <BsFillBellFill size={20} />
          ) : (
            Math.floor(progressValue) + '%'
          )}
        </C.ProgressIcon>

        <C.ProfileContainer
          onClick={() => setDropdownProfile(!dropdownProfile)}
        >
          <C.AvatarPic src={AvatarPic} className="h-16" />
          <C.UsernameLabel>
            {userData.first_name} {userData.last_name}
          </C.UsernameLabel>
        </C.ProfileContainer>
      </C.TopbarOptions>

      <C.DropdownProfile $dropdown={dropdownProfile}>
        <C.DropdownLink
          position="last"
          onClick={() => [logout(), navigate('/')]}
        >
          <C.DropdownIcon>
            <BsDoorOpenFill size={20} />
          </C.DropdownIcon>

          <p>Logout</p>
        </C.DropdownLink>
      </C.DropdownProfile>
    </C.HeaderTopbar>
  );
}
