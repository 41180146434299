import * as C from './styles';
import Loading from '../../../components/Loading';
import { Outlet, Navigate, useLocation } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';
import { useEffect } from 'react';

export default function PublicLayout() {
  const location = useLocation();
  const { isAuthenticated, checkAuthenticated, status } = useAuth();

  useEffect(() => {
    checkAuthenticated();
  }, [checkAuthenticated, isAuthenticated]);

  if (status.loading || isAuthenticated === null) return <Loading />;

  if (!isAuthenticated)
    return (
      <div className="p-0 m-0 box-border">
        <C.Container>
          <C.MainText>
            <span className="text-emerald-400">
            Flow TS Demo {'  '}
            </span>
          </C.MainText>

          <C.LoginContent>
            <Outlet />
          </C.LoginContent>
        </C.Container>
        <Loading />
      </div>
    );

  return <Navigate to="/" state={{ from: location }} replace />;
}
